import QuickBooksOnlineImage from "~/images/integrations/qbo.png";
import QuickBooksDesktopImage from "~/images/integrations/qbd.png";
import LabyxImage from "~/images/integrations/labyx.png";
import InstaHealthImage from "~/images/integrations/instahms.jpg";
import EFRISImage from "~/images/integrations/efris.jpg";


export const Images = {
    Integration: {
        QuickBooksOnline: QuickBooksOnlineImage,
        QuickBooksDesktop: QuickBooksDesktopImage,
        Labyx: LabyxImage,
        InstaHealth: InstaHealthImage,
        EFRIS: EFRISImage,
    }
}